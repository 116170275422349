import { queryFn } from "~/lib/client";
import {
  FormListResponse,
  FormPublicSchema,
  FormResponseListSchema,
  FormSchema,
} from "~/types/backend";

export function getFormList(access_token: string | undefined = "") {
  return {
    queryKey: ["form", "list"],
    queryFn: queryFn<FormListResponse>(
      "GET",
      `/formbuilder/forms`,
      access_token
    ),
    enabled: access_token !== undefined,
  };
}

export function getFormById(
  access_token: string | undefined = "",
  uuid: string
) {
  return {
    queryKey: ["form", uuid],
    queryFn: queryFn<FormSchema>(
      "GET",
      `/formbuilder/forms/uuid/${uuid}`,
      access_token
    ),
    enabled: access_token !== undefined,
  };
}

export function getFormByToken(token: string) {
  return {
    queryKey: ["form", token],
    queryFn: queryFn<FormPublicSchema>(
      "GET",
      `/formbuilder/forms/token/${token}`
    ),
    enabled: !!token,
  };
}

export function getFormResponses(
  access_token: string | undefined,
  form_uuid: string
) {
  return {
    queryKey: ["form", form_uuid, "responses"],
    queryFn: queryFn<FormResponseListSchema>(
      "GET",
      `/formbuilder/forms/uuid/${form_uuid}/responses`,
      access_token
    ),
    enabled: access_token !== undefined,
  };
}
