"use client";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAccessToken } from "~/features/auth/api/auth-hooks";
import { apiClient, constructHeaders } from "~/lib/client";
import {
  CreateFormResponseRequest,
  FormCreateRequest,
  FormResponseSchema,
  FormSchema,
  FormUpdateRequest,
} from "~/types/backend";
import {
  getFormById,
  getFormByToken,
  getFormList,
  getFormResponses,
} from "./formbuilder-querries";

export function useGetFormResponses(uuid: string) {
  const accessToken = useAccessToken();
  return useQuery({ ...getFormResponses(accessToken, uuid), retry: false });
}

export function useGetFormByToken(token: string) {
  return useQuery({ ...getFormByToken(token), retry: false });
}

export function useGetFormList() {
  const accessToken = useAccessToken();
  return useQuery({ ...getFormList(accessToken), retry: false });
}

export function useGetFormById(uuid: string) {
  const accessToken = useAccessToken();
  return useQuery({ ...getFormById(accessToken, uuid), retry: false });
}

type TCreateAnswerPayload = {
  token: string;
  data: CreateFormResponseRequest;
};

export function useCreateFormAnswer(
  onSuccess?: (response: FormResponseSchema) => void
) {
  return useMutation<FormResponseSchema, AxiosError, TCreateAnswerPayload>({
    mutationFn: async ({ token, data }) => {
      const response = await apiClient.post(
        `formbuilder/forms/token/${token}`,
        JSON.stringify(data)
      );
      return response.data;
    },
    onSuccess: (response) => {
      if (onSuccess) {
        onSuccess(response);
      }
    },
  });
}

type TCreateFormPayload = {
  data: FormCreateRequest;
};

export function useCreateForm(onSuccess?: (form: FormSchema) => void) {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();
  const headers = constructHeaders(accessToken || "");
  return useMutation<FormSchema, AxiosError, TCreateFormPayload>({
    mutationFn: async ({ data }) => {
      const response = await apiClient.post(
        `formbuilder/forms`,
        JSON.stringify(data),
        {
          headers,
        }
      );
      return response.data;
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: ["form"],
      });
      if (onSuccess) {
        onSuccess(response);
      }
    },
  });
}

type TUpdateFormPayload = {
  uuid: string;
  data: FormUpdateRequest;
};

export function useUpdateForm(onSuccess?: (form: FormSchema) => void) {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();
  const headers = constructHeaders(accessToken || "");
  return useMutation<FormSchema, AxiosError, TUpdateFormPayload>({
    mutationFn: async ({ data, uuid }) => {
      const response = await apiClient.put(
        `formbuilder/forms/uuid/${uuid}`,
        JSON.stringify(data),
        {
          headers,
        }
      );
      return response.data;
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: ["form"],
      });
      queryClient.setQueryData(["form", response.uuid], response);
      if (onSuccess) {
        onSuccess(response);
      }
    },
  });
}

export function useDeleteForm(onSuccess?: () => void) {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();
  const headers = constructHeaders(accessToken || "");
  return useMutation<void, AxiosError, string>({
    mutationFn: async (uuid) => {
      await apiClient.delete(`formbuilder/forms/uuid/${uuid}`, {
        headers,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["form"],
      });
      if (onSuccess) {
        onSuccess();
      }
    },
  });
}
